import * as React from "react";

const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>
      <p>Page not found.</p>
    </main>
  );
};

export default NotFoundPage;
